import * as React from "react";
import { graphql } from "gatsby";
import Post from '../components/Post';
import Layout from "../components/Layout";

export default function Template({ data }) {
  const title = data.markdownRemark.frontmatter.title;
  const date = data.markdownRemark.frontmatter.date;
  // const description = data.markdownRemark.frontmatter.description;
  const body = data.markdownRemark.frontmatter.body;
  return (
      <Layout>
        <Post 
          title={title}
          date={date}
          body={body}
        />
      </Layout>
  )
}

export const query = graphql`
query ($id: String) {
  markdownRemark(id: { eq: $id }) {
    frontmatter {
      body
      date
      description
      title
      slug
    }
  }
}
`
import * as React from "react";

const Post = ({ title, date, body}) => {
    return(
        <div className="post-container">
            <div className="post-left-margin"></div>
            <div className="post-content-container">
                <h1>{title}</h1>
                <p>{date}</p>
                <p>{body}</p>
            </div>
            <div className="post-right-margin"></div>
        </div>
    );
}

export default Post;